import { render, staticRenderFns } from "./DemoBadgeTabs.vue?vue&type=template&id=483a60fc&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBadge,VTab,VTabs})
